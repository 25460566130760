<template>
    <div>
        <div>
            <div class="closeBtn mb-2 text-right">
                <b-button variant="danger" size="sm" @click="close()">Close</b-button>
            </div>
            <h4 class="text-center" style="font-size: 24px; font-weight: 900">
                Score Card for: <span class="text-info">{{ exam.name }}</span>
            </h4>
            <div class="studentSheet mt-3" v-for="(student, studentIndex) in students" :key="studentIndex">
                <b-card header-tag="header" class="mt-3" footer-tag="footer">
                    <template #header>
                        <div class="question">
                            <!-- <p>Ques: {{ sheet.question }} </p>-->
                            <span class="mr-1">
                              {{ student.name }}
                            </span>
                            <span v-if="student.score > 0">(Score:
                                {{ student.score }} / {{ (totalMarks).toFixed(1) }}
                                , pct :{{
                                    Math.floor(
                                        (student.score / totalMarks) *
                                        100
                                    ) + "%"
                                }})
                            </span>
                        </div>
                    </template>
                    <b-card-body v-if="student.score != 0">
                        <div class="answer d-flex justify-content-between align-items-baseline">
                            <div class="ans">
                                <span v-for="(question, qindex) in questions" :key="qindex">
                                    {{ question.question_no }} ){{ updateTotalMarks[student.id + '_' + question.id]
                                    }},</span>
                            </div>
                        </div>
                    </b-card-body>
                </b-card>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import {
    required,
    requiredIf,
    integer
} from "vuelidate/lib/validators";
export default {
    async created() {
        this.fetchData = true;
        this.examId = this.$route.params.exam_id;
        await this.prepareScore();
    },
    components: {
    },
    data() {
        return {
            smsCount: null,
            fetchData: false,
            loadExams: false,
            deleteQuestionIndex: null,
            examId: null,
            exam: {},
            questions: [],
            answers: [],
            courseModulesOptions: [],
            courseoutcomesOptions: [],
            importFromExcel: false,
            students: [],
            saveBtn: {
                text: "Save",
                disabled: false,
            },
            totalScores: {}, // processed on updateTotalMarks computed proprty
            totalMarks: null, // processed on updateTotalQuestionScore method
            zIndex: -1,
            edit: false,
            courseModulesOptionsObject: {},
            orquestiongroups: {},
            orGroupQuestionIds: {}, // to calculate student totel score 
            // enableOrQuestiongroupmodel: false,
            viewHeader: {
                maxMark: true,
                co: true,
                module: true,
                blooms: true,
                orq: true,
            },
            importExcelFile: null,
            excelImportStatus: "",
            viewImportExcelModel: false,
            expandedEvaluationStudId: null,
            activeStudentQuestionsScores: {}, //pass to child
            autoEvaluationStatus: false,
            enableManageQuestionWindow: false,
            subQuestionParentIds: [], // avoid enter score for main question of subquestions
        };
    },
    computed: {
        // question without main question of subquestions
        evaluationQuestions() {
            return this.questions.filter((q) => {
                return !this.subQuestionParentIds.includes(q.id);
            });
        },
        updateTotalMarks() {
            this.answers.forEach((ans) => {
                let key = ans.student_id + '_' + ans.examquestion_id
                this.totalScores[key] = ans.score;
            });

            return this.totalScores;
        },
    },
    validations: {
        questions: {
            required,
            $each: {
                question_no: {
                    required,
                },
                maxMarks: {
                    required: requiredIf(function () {
                        return this.exam.positive_mark == null;
                    }),
                    integer,
                },
            },
        },
    },
    methods: {
        close(){
            this.closeCurrentTab();
        },
        getExam() {
            const examId = this.examId;
            const url = this.$store.getters.getAPIKey.mainAPI +
                `/publicexams/${examId}`;
            axios
                .get(url)
                .then((response) => {
                    this.exam = {};
                    this.exam = response.data;
                    response;
                })
                .catch((error) => {
                    error;
                });
        },
        async prepareScore() {
            this.getExam();
            const url =
                this.$store.getters.getAPIKey.mainAPI +
                `/score_summary/${this.examId}`;
            await axios
                .get(url)
                .then((response) => {
                    this.students = response.data.participants;
                    this.questions = response.data.questions;
                    this.questions.forEach((ques) => {
                        this.totalMarks += Number(ques.maximum_mark);
                    });
                    this.answers = response.data.answers;
                })
                .catch(() => {
                    this.$toast.error("Failed to load", {
                        position: "top",
                        duration: 3000,
                    });
                });
        },

    },
};
</script>
<style lang="scss">
.offlineExam table {
    width: 100%;
    text-align: center;
}
</style>
